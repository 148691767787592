<template>
  <div class="p-1">
    <div class="flex justify-between flex-wrap items-center mb-2">
      <h3 class="text-2xl">Pagos</h3>
      <a-button :to="{ name: 'create_payment' }" class="w-full md:w-auto" v-if="user.isAdmin">
        Registrar pago
      </a-button>
    </div>

    <a-table
        class="overflow-scroll"
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @delayed-filter:student="search({ student_like: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #toolbar>
        <v-select
          class="w-56"
          @input="search({ status: $event, page: 1 })"
          :clearable="false"
          v-model="query.status"
          :options="[
              { label: 'Pendiente por revisar', value: '1' },
              { label: 'Aprobado', value: '2' },
              { label: 'Rechazado', value: '3' },
              { label: 'Todos', value: null }
          ]"
          :reduce="$0 => $0.value"/>
      </template>

      <template #td(actions)="{ item }">
        <div class="flex space-x-2">
          <v-select
            v-if="item.status === 1"
            placeholder="Estado"
            class="w-32"
            :clearable="false"
            :loading="loading"
            :reduce="option => option.value"
            :options="[
                { label: 'Aprobado', value: 2 },
                { label: 'Rechazado', value: 3 }
            ]"
            @input="changePaymentStatus({ id: item.id, status: $event })"/>
          <a-button @click="deletePayment(item.id)" v-tooltip="`Eliminar pago`" v-if="item.status === 1">
            <a-icon name="trash" class="text-red-600" />
          </a-button>
<!--          <a-dropdown-item :to="{ name: 'create_period', query: { update: item.id } }">-->
<!--            Actualizar-->
<!--          </a-dropdown-item>-->
          <a-button @click="$router.push({ name: 'create_payment', query: { update: item.id } })" v-tooltip="`Editar pago`" v-if="item.status === 1">
            <a-icon name="edit" class="text-yellow-600" />
          </a-button>
          <template v-else>
            -
          </template>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      with: 'student,period',
      search: '',
      status: '1',
      sort_by: 'date,desc'
    },
    timeout: null,
    modal: false,
    selectedPayment: []
  }),
  computed: {
    ...mapState({
      pagination: state => state.payments.pagination,
      loading: state => state.payments.loading,
      user: ({ session }) => session.user
    }),
    columns() {
      return [
        { title: 'Fecha', key: 'date', display: true },
        { title: 'Factura', key: 'receipt_number', display: true },
        { title: 'Monto', key: 'amount', mutate: ({ key }) => `${'$' + parseFloat(key)}`, display: true },
        { title: 'Tipo', key: 'type', mutate: ({ key }) => this.parseType(key), display: true },
        { title: 'Estado', key: 'status', mutate: ({ key }) => this.parseStatus(key), display: true },
        { title: 'Estudiante', key: 'student', mutate: ({ key }) => `${key.first_name} ${key.last_name}`, display: true, filter: true, preventLocalFilter: true },
        { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: true },
        { title: 'Acciones', slot: 'actions', style: () => ({ width: `180px` }), alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter(column => column.display)
    }
  },
  methods: {
    parseType(type) {
      return {
        1: 'Efectivo',
        2: '100% Banco',
        3: 'Transferencia Bancaria',
        4: 'Transferencia de Curso',
        5: 'Zelle'
      }[type]
    },
    parseStatus(status) {
      return {
        1: 'Pendiente por revisar',
        2: 'Aprobado',
        3: 'Rechazado'
      }[status]
    },
    handleStatusChange(status) {
      if (status) {
        const query = this.$repositoryUtils.withoutInvalidValues({...this.$route.query, status })
        this.$router.replace({ query })
          .catch(() => null)
      } else {
        let routeQuery = this.$route.query
        delete routeQuery.status
        delete this.query.status

        const query = this.$repositoryUtils.withoutInvalidValues(query)

        this.$router.replace({ query })
          .catch(() => null)
      }

      this.search()
    },
    ...mapActions({
      fetchPayments: 'payments/fetchAll',
      deletePayment: 'payments/delete',
      changePaymentStatus: 'payments/changePaymentStatus'
    }),
    getTypeString(type) {
      switch (type) {
        case 1: return "Efectivo"
        case 2: return "100% Banco"
        case 3: return "Transferencia Bancaria"
        case 4: return "Transferencia de Curso"
        case 5: return "Zelle"
        default: "Tipo no definido"
      }
    },
    getStatusString(state) {
      switch (state) {
        case 1: return "Pendiente por revisar"
        case 2: return "Aprobado"
        case 3: return "Rechazado"
        default: return "Estado no definido"
      }
    },
    search(query = {}) {
      this.fetchPayments(this.$repositoryUtils.withoutInvalidValues({ ...this.query, ...query }))
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  },
  created() {
    this.query = {
      ...this.query,
      ...this.$route.query
    }
  }
}
</script>
