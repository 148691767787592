var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('div',{staticClass:"flex justify-between flex-wrap items-center mb-2"},[_c('h3',{staticClass:"text-2xl"},[_vm._v("Pagos")]),(_vm.user.isAdmin)?_c('a-button',{staticClass:"w-full md:w-auto",attrs:{"to":{ name: 'create_payment' }}},[_vm._v(" Registrar pago ")]):_vm._e()],1),_c('a-table',{staticClass:"overflow-scroll",attrs:{"columns":_vm.columns,"source":_vm.pagination.data,"loading":_vm.loading,"toolbar-column-filter":"","pagination":_vm.pagination},on:{"paginate":function($event){return _vm.search({ page: $event })},"delayed-filter:student":function($event){return _vm.search({ student_like: $event })},"toolbar(refresh)":function($event){return _vm.search({ page: 1 })}},scopedSlots:_vm._u([{key:"toolbar(search)",fn:function(){return [_c('a-input',{attrs:{"is-search":"","placeholder":"Buscar..."},on:{"search":function($event){return _vm.search({ search: $event, page: 1 })}}})]},proxy:true},{key:"toolbar",fn:function(){return [_c('v-select',{staticClass:"w-56",attrs:{"clearable":false,"options":[
              { label: 'Pendiente por revisar', value: '1' },
              { label: 'Aprobado', value: '2' },
              { label: 'Rechazado', value: '3' },
              { label: 'Todos', value: null }
          ],"reduce":function ($0) { return $0.value; }},on:{"input":function($event){return _vm.search({ status: $event, page: 1 })}},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}})]},proxy:true},{key:"td(actions)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"flex space-x-2"},[(item.status === 1)?_c('v-select',{staticClass:"w-32",attrs:{"placeholder":"Estado","clearable":false,"loading":_vm.loading,"reduce":function (option) { return option.value; },"options":[
                { label: 'Aprobado', value: 2 },
                { label: 'Rechazado', value: 3 }
            ]},on:{"input":function($event){return _vm.changePaymentStatus({ id: item.id, status: $event })}}}):_vm._e(),(item.status === 1)?_c('a-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Eliminar pago"),expression:"`Eliminar pago`"}],on:{"click":function($event){return _vm.deletePayment(item.id)}}},[_c('a-icon',{staticClass:"text-red-600",attrs:{"name":"trash"}})],1):_vm._e(),(item.status === 1)?_c('a-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Editar pago"),expression:"`Editar pago`"}],on:{"click":function($event){return _vm.$router.push({ name: 'create_payment', query: { update: item.id } })}}},[_c('a-icon',{staticClass:"text-yellow-600",attrs:{"name":"edit"}})],1):[_vm._v(" - ")]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }